<template>
  <v-app>
    <main>
      <div
        v-if="showLoginPage"
        id="container"
        class="container"
      >
        <div class="form-container sign-in-container">
          <form @submit.prevent="submit">
            <img
              :src="logo"
            >
            <h1 class="my-signin-style">
              Sign In
            </h1>
            <br>
            <br>
            <input
              id="email"
              v-model="email"
              type="email"
              placeholder="Email"
              name="email"
              required
            >
            <input
              id="password"
              v-model="password"
              type="password"
              placeholder="Enter your password"
              name="password"
              minlength="8"
              required
            >
            <div class="my-show-password-style">
              <label>
                <input
                  type="checkbox"
                  @click="showPassword()"
                >
                Show Password</label>
            </div>
            <router-link to="password/reset">
              Forgot your password?
            </router-link>
            <button
              type="submit"
            >
              <span>Sign In</span>
            </button>
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            >
            <div>
              <a
                href="https://www.facebook.com/dbsystems.com.au"
                class="fa fa-facebook"
              />
              <a
                href="https://twitter.com/dbsystems_au"
                class="fa fa-twitter"
              />
              <a
                href="https://www.instagram.com/dbsystems.com.au/"
                class="fa fa-instagram"
              />
              <a
                href="https://www.youtube.com/channel/UCEirQfvWgNoMkTwB_L_Wb0Q"
                class="fa fa-youtube"
              />
            </div>
          </form>
        </div>
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-right">
              <h1 class="my-welcome-style">
                Welcome to
              </h1>
              <div>
                <h1
                  class="my-dbs-style"
                  align="left"
                >
                  DBSystems
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dashboard-core-footer v-if="showLoginPage" />
    </main>
    <welcome-page
      v-if="showWelcomePage"
      ref="welcomepage"
      :user-email="email"
      :user-password="password"
      @login-failed="loginFailed"
    />
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { cdnUrl } from 'src/app-globals';
  import WelcomePage from './WelcomePage';

  export default {
    name: 'Login',

    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
      'welcome-page': WelcomePage,
    },

    data: () => ({
      password: '',
      email: '',
      loading: false,
      logo: `${cdnUrl}/website/NewDBSLogo.png`,
      showLoginPage: true,
      showWelcomePage: false,
    }),

    mounted () {
      this.checkValidSession();
    },

    methods: {
      submit () {
        this.showLoginPage = false;
        this.showWelcomePage = true;
      },
      clear () {
        this.$refs.form.reset();
        this.errors.clear('email');
      },
      checkValidSession () {
        var userExist = localStorage.getItem('ds-user');
        if (userExist !== null) {
          this.showLoginPage = false;
          this.showWelcomePage = true;
        }
      },
      showPassword () {
        var passwordStatus = document.getElementById('password');
        if (passwordStatus.type === 'password') {
          passwordStatus.type = 'text';
        } else {
          passwordStatus.type = 'password';
        }
      },
      loginFailed () {
        this.showWelcomePage = false;
        this.showLoginPage = true;
      },
    },
  };
</script>
<style scoped>
* {
box-sizing: border-box;
}
h1 {
font-weight: bold;
font-size: 40px;
padding: 5px;
letter-spacing: 10px;
word-spacing: 5px;
display: inline;
font-family:'Times New Roman', Times, serif;
}
h2 {
text-align: center;
}
.my-dbs-style {
  color: #FF3700;
}
.my-signage-style {
  color: #ECEFF1;
}
.my-welcome-style {
  color: #ECEFF1;
}
p {
font-size: 16px;
font-weight: 100;
line-height: 20px;
letter-spacing: 0.5px;
margin: 20px 0 30px;
color: #ECEFF1;
}
span {
font-size: 12px;
}
a {
color: #37474F;
font-size: 14px;
text-decoration: none;
margin: 15px 0;
}
button {
border-radius: 20px;
border: 1px solid #FF3700;
background-color: #FF3700;
color: #FFFFFF;
font-size: 12px;
font-weight: bold;
padding: 12px 45px;
letter-spacing: 1px;
text-transform: uppercase;
}
button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
button:hover span {
  padding-right: 10px;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}
form {
background-color: #ECEFF1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 0 50px;
height: 100%;
width: 100%;
text-align: center;
}
input {
background-color: #CFD8DC;
border: none;
padding: 12px 15px;
margin: 8px 0;
width: 80%;
}
.container {
padding: 16px;
object-fit: contain;
}
.form-container {
position:absolute;
top: 0;
height: 100%;
bottom: 200%;
}
.sign-in-container {
left: 0;
width: 50%;
height: 100%;
z-index: 2;
}
.overlay-container {
position: absolute;
top: 0;
left: 50%;
width: 50%;
height: 100%;
overflow: hidden;
transition: transform 0.6s ease-in-out;
z-index: 100;
}
.overlay {
background: #37474F;
background: -webkit-linear-gradient(to right, #37474F, #37474F);
background: linear-gradient(to right, #37474F, #37474F);
background-repeat: no-repeat;
background-size: cover;
background-position: 0 0;
color: #FFFFFF;
position: relative;
left: -100%;
height: 100%;
width: 200%;
}
.overlay-panel {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 0 40px;
text-align: center;
top: 0;
height: 100%;
width: 50%;
}
.overlay-right {
right: 0;
transform: translateX(0);
}
.my-signin-style {
  color: #37474F;
}
.fa {
border: 1px solid #DDDDDD;
border-radius: 50%;
display: inline-flex;
justify-content: center;
align-items: center;
margin: 0 5px;
height: 40px;
width: 40px;
margin: 40px 0;
margin-right: 15px;
}
.fa:hover {
  opacity: 0.7;
}
.fa-facebook {
  background: #3B5998;
  color: white;
}
.fa-twitter {
  background: #55ACEE;
  color: white;
}
.fa-instagram {
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  color: white;
}
.fa-youtube {
  background: #bb0000;
  color: white;
}
@media (max-width: 767px) {
  .overlay-container {
    display: none;
  }
}
@media (max-width: 767px) {
  .sign-in-container {
    width: 100%;
    display: block;
  }
}
input[type="checkbox"] {
  width: 13px;
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: middle;
  position: relative;
  top: -1px;
  *overflow: hidden;
}
.my-show-password-style {
  display: flex;
  width: 80%;
}
label {
  color: #37474F;
  font-size: 14px;
  text-decoration: none;
}
::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
