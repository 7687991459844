<template>
  <div class="main-div">
    <iframe
      id="iframe"
      style="display:none;"
      :src="scDomainUrl"
    />
    <iframe
      id="scpiframe"
      style="display:none;"
      :src="scpDomainUrl"
    />
    <div>
      <select-app-modules
        v-if="showAppModules"
        @selectedApp="routeToApp"
      />
    </div>
    <div class="slid-div">
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <p class="quote-style">
          {{ randomSlides.quote }} <br>
          -<span class="author-style"> {{ randomSlides.author }}</span>
        </p>
      </v-row>
    </div>
    <div class="container">
      <div class="item item-1" />
      <div class="item item-2" />
      <div class="item item-3" />
      <div class="item item-4" />
    </div>
  </div>
</template>
<script>
import axios from 'src/app-axios';
import Constants from 'src/constants';
import ErrorStore from 'src/errors';
import SelectAppModules from './SelectAppModules.vue';
import { scAppDomainUrl, scpAppDomainUrl } from 'src/app-globals';

export default {
  name: 'WelcomePage',
  components: {
    'select-app-modules': SelectAppModules,
  },

  props: {
    userEmail: {
      type: String,
      required: true,
    },
    userPassword: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      slides: [
        {
          quote: '"The greatest glory in living lies not in never falling, but in rising every time we fall."',
          author: 'Nelson Mandela',
        },
        {
          quote: '"Always remember that you are absolutely unique. Just like everyone else."',
          author: 'Margaret Mead',
        },
        {
          quote: '"You will face many defeats in life, but never let yourself be defeated."',
          author: 'Maya Angelou',
        },
        {
          quote: '"The future belongs to those who believe in the beauty of their dreams."',
          author: 'Eleanor Roosevelt',
        },
        {
          quote: '"Imagination is more important than knowledge."',
          author: 'Albert Einstein',
        },
      ],
      showArrows: false,
      errors: new ErrorStore(),
      appModules: [],
      showAppModules: false,
      scDomainUrl: scAppDomainUrl,
      scpDomainUrl: scpAppDomainUrl,
    };
  },
  computed: {
    randomSlides () {
      const slideItem = this.slides[Math.floor(Math.random() * this.slides.length)];
      return slideItem;
    },
  },
  async mounted () {
    await this.loadOnMount();
    window.addEventListener('message', this.iframeResponse, false);
    window.addEventListener('message', this.scpIframeResponse, false);
  },
  methods: {
    getAppModules () {
      const userDetails = this.$store.getters.getUser;
      this.appModules = userDetails.user.organization.app_modules;
    },
    loadOnMount () {
      var userExist = localStorage.getItem('ds-user');
      if (userExist !== null) {
        this.loginToPrevSession();
      } else {
        this.login();
      }
    },
    async chooseYourApp () {
      await this.getAppModules();
      if (this.appModules.length > 1) {
        this.showAppModules = true;
        return;
      }
      this.routeToApp(this.appModules[0].name);
    },
    routeToApp (val) {
      if (val === 'digital-signage') {
        setTimeout(() => {
          this.$router.push({ name: 'Dashboard' });
        }, 2000);
      } else if (val === 'swift-check-in') {
        this.loginToSwiftCheckIn();
      } else if (val === 'swift-check-in-projects') {
        this.loginToSwiftCheckInProjects();
      }
    },
    login () {
      axios.get('sanctum/csrf-cookie').then(response => {
        this.$store
        .dispatch('login', {
          email: this.userEmail,
          password: this.userPassword,
        })
        .then(response => {
          this.chooseYourApp();
        })
        .catch(error => {
          this.errors.record(error.response.data.errors);
          this.$store.dispatch('alert/onAlert', {
            message: 'These credentials do not match our records.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          this.$emit('login-failed');
        });
      });
    },
    loginToPrevSession () {
      var userExist = localStorage.getItem('ds-user');
      if (userExist === null) {
        return;
      }
      axios.get('api/me')
      .then(response => {
        this.loading = false;
        this.chooseYourApp();
      })
      .catch(errors => {
        axios.post('api/logout')
        .then(response => {
          this.loading = false;
          this.$store.dispatch('logout');
          this.$emit('login-failed');
        });
      });
    },
    loginToSwiftCheckIn () {
      const userDetails = this.$store.getters.getUser;
      const userExternalId = userDetails.user.external_id;
      this.$store.dispatch('userprofile/loginToSwiftCheckIn', {
        userId: userExternalId,
      }).then(response => {
        const dsUserDetails = localStorage.getItem('ds-user');
        const scUserDetails = localStorage.getItem('sc-user');
        document.getElementById('iframe').contentWindow.postMessage({
          dsUser: dsUserDetails,
          scUser: scUserDetails,
        }, this.scDomainUrl);
        setTimeout(() => {
           this.$store.dispatch('alert/onAlert', {
            message: 'The connection has timed out, please login again.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          setTimeout(() => {
            this.$router.push({ name: 'Clear Local Storage' });
          }, 2000);
        }, 6000);
      }).catch(() => {
        this.loading = false;
        this.$store.dispatch('logout');
        this.$emit('login-failed');
      });
    },
    async iframeResponse (response) {
      if (response.origin !== this.scDomainUrl) {
        return;
      }
      window.location.href = this.scDomainUrl;
    },
    loginToSwiftCheckInProjects () {
      const userDetails = this.$store.getters.getUser;
      const userExternalId = userDetails.user.external_id;
      this.$store.dispatch('userprofile/loginToSwiftCheckInProjects', {
        userId: userExternalId,
      }).then(response => {
        const dsUserDetails = localStorage.getItem('ds-user');
        const scpUserDetails = localStorage.getItem('scp-user');
        document.getElementById('scpiframe').contentWindow.postMessage({
          dsUser: dsUserDetails,
          scpUser: scpUserDetails,
        }, this.scpDomainUrl);
        setTimeout(() => {
           this.$store.dispatch('alert/onAlert', {
            message: 'The connection has timed out, please login again.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          setTimeout(() => {
            this.$router.push({ name: 'Clear Local Storage' });
          }, 2000);
        }, 6000);
      }).catch(() => {
        this.loading = false;
        this.$store.dispatch('logout');
        this.$emit('login-failed');
      });
    },
    async scpIframeResponse (response) {
      if (response.origin !== this.scpDomainUrl) {
        return;
      }
      window.location.href = this.scpDomainUrl;
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.item {
  width: 25px;
  height: 25px;
  position: absolute;
}

.item-1 {
  background-color: #C62828;
  top: 0;
  left: 0;
  z-index: 1;
  animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2 {
  background-color: #7A45E5;
  top: 0;
  right: 0;
  animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3 {
  background-color: #1B91F7;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4 {
  background-color: #FAC24C;
  bottom: 0;
  left: 0;
  animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}
.main-div {
  background-color: #263238;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
@media only screen and (max-width:480px) {
  .main-div {
    position: relative;
  }
}

@keyframes item-1_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(0, 35px)}
  50% {transform: translate(35px, 35px)}
  75% {transform: translate(35px, 0)}
}

@keyframes item-2_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(-35px, 0)}
  50% {transform: translate(-35px, 35px)}
  75% {transform: translate(0, 35px)}
}

@keyframes item-3_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(0, -35px)}
  50% {transform: translate(-35px, -35px)}
  75% {transform: translate(-35px, 0)}
}

@keyframes item-4_move {
  0%, 100% {transform: translate(0, 0)}
  25% {transform: translate(35px, 0)}
  50% {transform: translate(35px, -35px)}
  75% {transform: translate(0, -35px)}
}
.quote-style {
  color: #ECEFF1;
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 18px;
  letter-spacing: 2px;
  max-width:50ch;
  word-wrap:break-word;
  text-align: center;
  margin: auto;
}
@media only screen and (max-width:480px) {
  .quote-style {
    max-width:40ch;
  }
}
.author-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 18px;
  letter-spacing: 2px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.slid-div {
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
