<template>
  <v-dialog
    v-model="dialog"
    max-width="850px"
    dark
    persistent
    content-class="main-dialog"
  >
    <br>
    <h2 class="title-style">
      >> Please choose the application you want to sign in
    </h2>
    <br>
    <div class="pa-4 text-center">
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <template v-for="(item, i) in appModules">
          <v-col
            :key="i"
            cols="10"
            sm="5"
            md="5"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="mx-auto"
                color="grey lighten-4"
              >
                <div
                  v-for="(img, x) in appImages"
                  :key="x"
                >
                  <v-img
                    v-if="item.name === img.name"
                    :src="img.url"
                    :aspect-ratio="16/9"
                    class="img-style"
                    @click="selectedApp(item.name)"
                  >
                    <p class="app-name">
                      {{ item.name | nameConverter }}
                    </p>
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out blue-grey darken-4 v-card--reveal text-h2 white--text"
                        style="height: 100%;"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-card-title class="text-h6 white--text">
                              <p class="app-name">
                                {{ item.name | nameConverter }}
                              </p>
                            </v-card-title>
                            <v-icon class="right-arrow-style">
                              mdi-chevron-double-right
                            </v-icon>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </div>
    <br>
  </v-dialog>
</template>
<script>
  import { cdnUrl } from 'src/app-globals';
  export default {
    name: 'SelectAppModules',
    filters: {
      nameConverter (val) {
        if (val === 'digital-signage') {
          return 'Swift Signage';
        } else if (val === 'swift-check-in') {
          return 'Swift Check-In EzyFM';
        } else if (val === 'swift-check-in-projects') {
          return 'Swift Check-In EzyBuild';
        }
      },
    },
    mixins: [global],
    data () {
      return {
        appModules: [],
        dialog: true,
        appImages: [
          {
            url: `${cdnUrl}/website/digital-signage.jpg`,
            name: 'digital-signage',
          },
          {
            url: `${cdnUrl}/website/swift-check-in.jpg`,
            name: 'swift-check-in',
          },
          {
            url: `${cdnUrl}/website/swift-check-in-projects.png`,
            name: 'swift-check-in-projects',
          },
        ],
      };
    },
    watch: {
      dialog: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      await this.getAppModules();
    },
    methods: {
      getAppModules () {
        const userDetails = this.$store.getters.getUser;
        this.appModules = userDetails.user.organization.app_modules;
      },
      selectedApp (app) {
        this.dialog = false;
        this.$emit('selectedApp', app);
      },
    },
  };
</script>
<style>
.main-dialog {
  background-color: white !important;
  border-radius: 25px;
}
</style>
<style scoped>
.title-style {
  color: #263238;
  font-family: 'Times New Roman', Times, serif;
  font-size: 22px;
  margin-left: 30px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.app-name {
  font-size: 25px;
  font-weight: bold;
  color: white;
  font-family: 'Times New Roman', Times, serif;
}
.right-arrow-style {
  font-size: 40px;
  font-weight: bold;
  color: #FF3700;
  animation: blinker 1s linear infinite;
}
.img-style {
  cursor: pointer;
}
@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
